<template>
    <div>
        <v-dialog
            overlay=false
            persistent
            transition="dialog-bottom-transition"
            v-model="sendContractDialog"
            width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendContractToSales') }}</v-card-title>
                <v-card-text class="white">
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="width-1-pct"></th>
                                            <th class="pl-1">{{ $t('message.name')}}</th>
                                            <th class="pl-1">{{ $t('message.email')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="salesColleague != null">
                                        <tr>
                                            <td>
                                                <v-checkbox
                                                    :value="salesColleague.Salesperson.userid"
                                                    class="mt-1"
                                                    dense
                                                    hide-details
                                                    v-model="selectedContacts"
                                                ></v-checkbox>
                                            </td>
                                            <td>{{ salesColleague.Salesperson.name }}</td>
                                            <td>{{ salesColleague.Salesperson.userid }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="3">{{ $t('message.noContactsFound') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3 white">
                    <v-spacer/>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="sendContractDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.send"
                        class="ml-3"
                        color="info"
                        small
                        @click="sendContractToSalesColleague()"
                    >{{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
<!--        <PrintContractBlob-->
<!--            :contract-id="contractId"-->
<!--            :key="updateKey"-->
<!--            @pdf-uploading="contractUploading"-->
<!--            @pdf-uploaded="contractUploaded"-->
<!--            @pdf-not-uploaded="contractNotUploaded()"-->
<!--            v-if="hasContract"-->
<!--        />-->
    </div>
</template>

<script>
import {api} from "Api";

// const  PrintContractBlob = () => import("Views/v1/contracts/PrintContractBlob");
export default {
    name: "EmailContractToSales",
    props: ['contractId', 'dialog', 'dialog-closed', 'salesColleague', 'updateKey'],
    // components: {PrintContractBlob},
    data() {
        return {
            contractUrl: null,
            hasContract: false,
            loading: {
                contract: false,
                send: false
            },
            selectedContacts: [],
            send_contract_dialog: false
        }
    },
    computed: {
        sendContractDialog: {
            get() {
                return this.send_contract_dialog;
            },
            set(value){
                this.send_contract_dialog = value
                if(value == false) {
                    // this.hasContract = false
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        // contractNotUploaded() {
        //     this.loading.send = false
        // },
        // contractUploaded(url) {
        //     this.contractUrl = url
        //     this.loading.contract = false
        //     this.loading.send = false
        // },
        // contractUploading() {
        //     this.loading.send = true
        // },
        sendContractToSalesColleague() {
            let promise = new Promise((resolve, reject) => {
                api
                    .put('/contracts/' + this.contractId + '/send-to-sales',{
                        contacts: this.selectedContacts,
                        url: this.contractUrl
                    })
                    .then(response => {
                        if(response.data.status == 'success') {
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
            if(this.selectedContacts.length > 0) {
                this.loading.send = true
                promise
                    .then(() => {
                        this.$toast.success(this.$t('message.successes.contractSent'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.send = false
                        this.sendContractDialog = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.contractNotSent'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.send = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.pleaseSelectOneContact'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            this.send_contract_dialog = value
            if(value){
                this.loading.send = true
                api
                    .put('/contracts/' + this.contractId + '/pdf')
                    .then((response) => {
                        if (response.data.status == 'success') {
                            this.loading.send = false
                            this.contractUrl = response.data.pdfUrl
                        } else {
                            this.$toast.error(this.$t('message.errors.pdfError'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                })
                            this.loading.send = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        this.loading.send = false
                    })
            }
        }
    },
    created() {
        this.selectedContacts = [];
    },
    mounted() {
        this.selectedContacts = [];
        if(this.salesColleague) {
            this.selectedContacts.push(this.salesColleague.Salesperson.userid)
        }
    }
}
</script>

<style scoped>
.v-data-table td {
    vertical-align: middle !important;
}
</style>